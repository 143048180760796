import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/ubuntu/flomesh.io-main/src/layouts/Fixed/DocMdx.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Pipy 启动可以从静态文件或者 HTTP 地址加载脚本，从 `}<a parentName="p" {...{
        "href": "/release/history/0.10.0-1/"
      }}>{`0.10.0-1`}</a>{` 开始 Pipy 还可以从内置的 `}<a parentName="p" {...{
        "href": "/operating/pipy-repo/"
      }}>{`Repo`}</a>{` 启动。`}</p>
    <h2 {...{
      "id": "参数"
    }}>{`参数`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ pipy -h
Usage: pipy [options] <script filename>

Options:
  -h, -help, --help                    Show help information
  -v, -version, --version              Show version information
  --list-filters                       List all filters
  --help-filters                       Show detailed usage information for all filters
  --log-level=<debug|info|warn|error>  Set the level of log output
  --verify                             Verify configuration only
  --reuse-port                         Enable kernel load balancing for all listening ports
  --admin-port=<port>                  Enable administration service on the specified port
`}</code></pre>
    <h3 {...{
      "id": "--list-filters"
    }}><inlineCode parentName="h3">{`--list-filters`}</inlineCode></h3>
    <p><inlineCode parentName="p">{`--list-filters`}</inlineCode>{` 列出 Pipy 的所有过滤器。`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`acceptTLS           (target)                                    Accepts and TLS-offloads a TLS-encrypted stream
connect             (target[, options])                         Sends data to a remote endpoint and receives data from it
connectTLS          (target)                                    Connects to a pipeline session with TLS ecryption
decompressHTTP      ([enable])                                  Decompresses HTTP message bodies based on Content-Encoding header
decompressMessage   (algorithm)                                 Decompresses message bodies
...
`}</code></pre>
    <h3 {...{
      "id": "--help-filters"
    }}><inlineCode parentName="h3">{`--help-filters`}</inlineCode></h3>
    <p>{`展示过滤器详细的使用信息。`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`
acceptTLS(target)
=================

  Accepts and TLS-offloads a TLS-encrypted stream

  target - <string> Name of the pipeline to send TLS-offloaded stream to


connect(target[, options])
==========================

  Sends data to a remote endpoint and receives data from it

  target  - <string|function> Remote endpoint in the form of \`<ip>:<port>\`
  options - <object> Includes bufferLimit, retryCount, retryDelay


connectTLS(target)
==================

  Connects to a pipeline session with TLS ecryption

  target - <string> Name of the pipeline to connect to


decompressHTTP([enable])
========================

  Decompresses HTTP message bodies based on Content-Encoding header

  enable - <function> Returns true to decompress or false otherwise
...
`}</code></pre>
    <h3 {...{
      "id": "--log-level"
    }}><inlineCode parentName="h3">{`--log-level`}</inlineCode></h3>
    <p>{`设置日志级别：`}<inlineCode parentName="p">{`debug`}</inlineCode>{`、`}<inlineCode parentName="p">{`info`}</inlineCode>{`、`}<inlineCode parentName="p">{`warn`}</inlineCode>{`、`}<inlineCode parentName="p">{`error`}</inlineCode>{`。`}</p>
    <p>{`使用方式：`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`pipy --log-level=debug main.js
`}</code></pre>
    <h3 {...{
      "id": "--verify"
    }}><inlineCode parentName="h3">{`--verify`}</inlineCode></h3>
    <p>{`验证配置文件的正确性。`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`pipy()

.listen(8080)
  .serveHttP(
    msg => new Message(msg.body)
  )
`}</code></pre>
    <p>{`比如将 `}<inlineCode parentName="p">{`serveHTTP`}</inlineCode>{` 改成 `}<inlineCode parentName="p">{`serveHttP`}</inlineCode>{`：`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`pipy --verify main.js

2021-10-08 17:24:39 [error] [pjs] Line 4:  .serveHttP(
2021-10-08 17:24:39 [error] [pjs]                    ^
2021-10-08 17:24:39 [error] [pjs] Error: not a function
2021-10-08 17:24:39 [error] [pjs] Backtrace:
2021-10-08 17:24:39 [error]     In (root) at line 4 column 13
Done.
`}</code></pre>
    <h3 {...{
      "id": "--reuse-port"
    }}><inlineCode parentName="h3">{`--reuse-port`}</inlineCode></h3>
    <p>{`为所有监听的端口开启内核级的负载均衡，参考 socket 选项 `}<inlineCode parentName="p">{`SO_REUSEPORT`}</inlineCode>{`。`}</p>
    <h3 {...{
      "id": "--admin-port"
    }}><inlineCode parentName="h3">{`--admin-port`}</inlineCode></h3>
    <p>{`启动 Pipy 内置的 `}<a parentName="p" {...{
        "href": "/operating/admin-gui/"
      }}>{`Web 控制台`}</a>{`，并指定端口；如未指定端口，不会开启控制台。若以 Repo 的方式启动，则无需该参数，默认端口为 `}<inlineCode parentName="p">{`6060`}</inlineCode>{`。`}</p>
    <pre><code parentName="pre" {...{}}>{`$ pipy --admin-port=6080
2021-10-08 18:21:03 [info] [codebase] Starting codebase service...
2021-10-08 18:21:03 [info] [listener] Listening on port 6080 at ::

$ pipy
2021-10-08 18:20:42 [info] [codebase] Starting codebase service...
2021-10-08 18:20:42 [info] [listener] Listening on port 6060 at ::
`}</code></pre>
    <h2 {...{
      "id": "进程信号"
    }}>{`进程信号`}</h2>
    <p>{`Pipy 运行时可以接收三种进程信号：`}<inlineCode parentName="p">{`SIGTSTP`}</inlineCode>{`、`}<inlineCode parentName="p">{`SIGHUP`}</inlineCode>{`、`}<inlineCode parentName="p">{`SIGINT`}</inlineCode>{`。使用方式：`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ kill -SIGNAL PID
`}</code></pre>
    <h3 {...{
      "id": "sigtstp"
    }}><inlineCode parentName="h3">{`SIGTSTP`}</inlineCode></h3>
    <p>{`通过发送 `}<inlineCode parentName="p">{`SIGTSTP`}</inlineCode>{` 信号可以让 Pipy 进程转储当前的状态：`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`2021-10-08 21:07:49 [info] Received SIGTSTP, dumping...

CLASS                                                   #INSTANCES
--------------------------------------------------------------------
  Object                                                2
  pipy::Configuration                                   1
  pipy::Console                                         1
  pipy::Data                                            1
  pipy::Global                                          1
  pipy::Hessian                                         1
  pipy::Inbound                                         1
  pipy::JSON                                            1
  pipy::OS                                              1
  pipy::Pipy                                            1
  pipy::Pipy::Script                                    1
  pipy::Pipy::Store                                     1
  pipy::XML                                             1
  pipy::algo::Algo                                      1
  pipy::crypto::Crypto                                  1
  pipy::http::Http                                      1
  pjs::Constructor<pipy::Data>                          1
  pjs::Constructor<pipy::Message>                       1
  pjs::Constructor<pipy::MessageEnd>                    1
  pjs::Constructor<pipy::MessageStart>                  1
  pjs::Constructor<pipy::Netmask>                       1
  pjs::Constructor<pipy::Session>                       1
  pjs::Constructor<pipy::SessionEnd>                    1
  pjs::Constructor<pipy::URL>                           1
  pjs::Constructor<pipy::XML::Node>                     1
  pjs::Constructor<pipy::algo::Cache>                   1
  pjs::Constructor<pipy::algo::HashingLoadBalancer>     1
  pjs::Constructor<pipy::algo::LeastWorkLoadBalancer>   1
  pjs::Constructor<pipy::algo::Percentile>              1
  pjs::Constructor<pipy::algo::ResourcePool>            1
  pjs::Constructor<pipy::algo::RoundRobinLoadBalancer>  1
  pjs::Constructor<pipy::algo::URLRouter>               1
  pjs::Constructor<pipy::crypto::Certificate>           1
  pjs::Constructor<pipy::crypto::Cipher>                1
  pjs::Constructor<pipy::crypto::Decipher>              1
  pjs::Constructor<pipy::crypto::Hash>                  1
  pjs::Constructor<pipy::crypto::Hmac>                  1
  pjs::Constructor<pipy::crypto::JWK>                   1
  pjs::Constructor<pipy::crypto::JWT>                   1
  pjs::Constructor<pipy::crypto::PrivateKey>            1
  pjs::Constructor<pipy::crypto::PublicKey>             1
  pjs::Constructor<pipy::crypto::Sign>                  1
  pjs::Constructor<pipy::crypto::Verify>                1
  pjs::Constructor<pipy::http::File>                    1
  pjs::Constructor<pjs::Array>                          1
  pjs::Constructor<pjs::Boolean>                        1
  pjs::Constructor<pjs::Date>                           1
  pjs::Constructor<pjs::Number>                         1
  pjs::Constructor<pjs::Object>                         1
  pjs::Constructor<pjs::RegExp>                         1
  pjs::Constructor<pjs::String>                         1
  pjs::Function                                         1
TOTAL                                                   53

DATA              CURRENT(KB)  PEAK(KB)
-----------------------------------------
Codebase Service  0            0
Cipher            0            0
Decipher          0            0
Hmac              0            0
Hash              0            0
Sign              0            0
Verify            0            0
http.File         0            0
Codebase          4            4
Codebase Store    0            0
Unknown           0            0
Script            0            0
HTTP Encoder      0            0
TLS               0            0
Message           0            0
TOTAL             4            n/a

PIPELINE              #ALLOCATED  #ACTIVE
-------------------------------------------
  /main.js [:::8080]  0           0
TOTAL                 0           0

INBOUND  #CONNECTIONS  BUFFERED(KB)
-------------------------------------
8080     0/0           0
TOTAL    0             0

OUTBOUND  #CONNECTIONS  BUFFERED(KB)  #OVERFLOWED  MAX_CONN_TIME  AVG_CONN_TIME
---------------------------------------------------------------------------------
`}</code></pre>
    <h3 {...{
      "id": "sighup"
    }}><inlineCode parentName="h3">{`SIGHUP`}</inlineCode></h3>
    <p>{`Pipy 进程在接收到 `}<inlineCode parentName="p">{`SIGHUP`}</inlineCode>{` 命令后，会重新加载脚本。`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ pipy main.js
2021-10-08 21:05:21 [info] [config]
2021-10-08 21:05:21 [info] [config] Module /main.js
2021-10-08 21:05:21 [info] [config] ===============
2021-10-08 21:05:21 [info] [config]
2021-10-08 21:05:21 [info] [config]  [Listen on :::8080]
2021-10-08 21:05:21 [info] [config]  ----->|
2021-10-08 21:05:21 [info] [config]        |
2021-10-08 21:05:21 [info] [config]       serveHTTP
2021-10-08 21:05:21 [info] [config]        |
2021-10-08 21:05:21 [info] [config]  <-----|
2021-10-08 21:05:21 [info] [config]
2021-10-08 21:05:21 [info] [listener] Listening on port 8080 at ::
2021-10-08 21:05:53 [info] [config]
2021-10-08 21:05:53 [info] [config] Module /main.js
2021-10-08 21:05:53 [info] [config] ===============
2021-10-08 21:05:53 [info] [config]
2021-10-08 21:05:53 [info] [config]  [Listen on :::8080]
2021-10-08 21:05:53 [info] [config]  ----->|
2021-10-08 21:05:53 [info] [config]        |
2021-10-08 21:05:53 [info] [config]       serveHTTP
2021-10-08 21:05:53 [info] [config]        |
2021-10-08 21:05:53 [info] [config]  <-----|
2021-10-08 21:05:53 [info] [config]
2021-10-08 21:05:53 [info] Script reloaded
`}</code></pre>
    <h3 {...{
      "id": "sigint"
    }}><inlineCode parentName="h3">{`SIGINT`}</inlineCode></h3>
    <p>{`通过键盘上的 `}<inlineCode parentName="p">{`CTRL + C`}</inlineCode>{` 可以向进程发送 `}<inlineCode parentName="p">{`SIGINT`}</inlineCode>{` 信号，进程收到信号后进入退出流程。假如有活跃的`}<a parentName="p" {...{
        "href": "/intro/concepts"
      }}>{`管道`}</a>{`，退出流程可能会比较久。此时再次按下 `}<inlineCode parentName="p">{`CTRL + C`}</inlineCode>{` 再次发送 `}<inlineCode parentName="p">{`SIGINT`}</inlineCode>{` 信号，则会强制进程退出。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      